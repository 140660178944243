var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-8"
  }, [_c('v-menu', {
    attrs: {
      "value": _vm.contextMenu.item,
      "offset-y": "",
      "absolute": "",
      "position-x": _vm.contextMenu.x,
      "position-y": _vm.contextMenu.y
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openNewTab(_vm.contextMenu.item);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-4 primary--text"
  }, [_vm._v("mdi-open-in-new")]), _vm._v("Open in new tab")], 1)], 1)], 1), _c('v-card', {
    staticClass: "rounded-lg"
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_c('v-text-field', {
    staticClass: "mr-2",
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "dark": "",
      "outlined": "",
      "rounded": "",
      "dense": "",
      "hide-details": "",
      "placeholder": "Filter...",
      "append-icon": "mdi-magnify"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.clickAddCustomer
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "customers",
    attrs: {
      "dense": "",
      "loading": _vm.loading,
      "items-per-page": 250,
      "hide-default-footer": "",
      "disable-sort": "",
      "items": _vm.customers,
      "item-key": "xid",
      "headers": _vm.headers
    },
    on: {
      "click:row": _vm.clickCustomer,
      "contextmenu:row": _vm.rightClickCustomer
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.paginate_pages,
      "total-visible": 15
    },
    on: {
      "input": _vm.onPaginate
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class='pt-8'>

    <v-menu :value="contextMenu.item" offset-y absolute :position-x="contextMenu.x" :position-y="contextMenu.y" >
      <v-list dense>
        <v-list-item link @click="openNewTab(contextMenu.item)"><v-icon class="mr-4 primary--text">mdi-open-in-new</v-icon>Open in new tab</v-list-item>
      </v-list>
    </v-menu>

    <v-card class='rounded-lg'>
      <v-card-title class='primary white--text'>
        <v-text-field
          dark outlined rounded dense hide-details
          class='mr-2' style='max-width:400px'
          placeholder='Filter...' append-icon='mdi-magnify'
          v-model='filter'
        />
        <v-spacer></v-spacer>
        <v-btn fab small text dark @click='clickAddCustomer'><v-icon>mdi-plus</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols=12 class=''>
            <v-data-table
              dense
              :loading='loading'
              :items-per-page="250" hide-default-footer disable-sort
              class='customers'
              :items='customers'
              item-key='xid'
              :headers='headers'
              @click:row='clickCustomer'
              @contextmenu:row="rightClickCustomer"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 class='' style=''>
            <v-pagination v-model="page" :length="paginate_pages" :total-visible='15' @input='onPaginate' />
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

  </v-container>
</template>

<style lang="scss">
.customers{
  tbody td:hover{ cursor: pointer; }
}
.v-main{ overflow-y: auto; }
</style>


<script>
import { /* mapGetters, */  mapActions } from 'vuex';

export default {
  name: 'Customers',
  components: {
  },
  data(){ return {
    loading: false,
    customers: [],
    headers: [
      { text: "Customer", value: 'name' }
    ],
    paginate_total: 0,
    paginate_pages: 0,
    page: 1,
    filter: '',
    contextMenu: {
      item: null,
      x: null,
      y: null,
    }
  }},
  watch: {
    $route: { handler(){ this.SET_TITLE('Customers'); }, immediate: true },
    filter(){
      this.page = 1;
      this.refreshCustomers(true);
    }
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "PROMPT", "NAVIGATE"]),
    ...mapActions("API", ["API"]),

    refreshCustomers(debounce){
      this.loading = true;
      let autosave = debounce ? 500 : null;
      let data = { filter: this.filter };
      return this.API({ method: 'POST', endpoint: `customers/list?page=${this.page}`, data, autosave })
      .then(results => {
        this.customers = results.data;
        this.paginate_total = results.total;
        this.paginate_pages = results.last_page;
      })
      .finally(() => { this.loading = false; });
    },

    clickAddCustomer(){
      this.PROMPT({ title: "New Customer", message: 'Customer Name:' })
      .then(name => {
        if(!name) return;
        let data = { name };
        this.loading = true;
        this.API({ method: 'POST', endpoint: 'customers/create', data })
        .then(this.clickCustomer)
        .finally(() => { this.loading = false; });
      });
    },

    clickCustomer(customer){
      this.NAVIGATE(`crm/${customer.xid}`);
    },

    rightClickCustomer(e, row){
      let customer = row.item;
      this.contextMenu = { ...this.contextMenu,
        item: customer,
        x: e.clientX, y: e.clientY
      };
      e.preventDefault();
    },

    openNewTab(customer){
      window.open(`crm/${customer.xid}`, '_blank');
    },

    onPaginate(){
      this.refreshCustomers();
      this.$parent.$el.scrollTop = 0;
    }

  },
  mounted(){
    this.refreshCustomers();
  }
}
</script>
